import { EMBEDLY_API_KEY } from 'app/config'

// This is a basic implementation of the Embedly API (without the whole JS library, to save on bundle size).
// Embedly API: https://embed.ly/docs/api/endpoints/extract

export const fetchEmbedlyPreview = async (url) => {
    if (!url) {
        return { error: 'No URL provided' }
    }

    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), 10000)

    try {
        const embedlyUrl = `https://api.embedly.com/1/extract?key=${EMBEDLY_API_KEY}&url=${encodeURIComponent(url)}&format=json`

        const response = await fetch(embedlyUrl, {
            signal: controller.signal,
            headers: {
                'Accept': 'application/json'
            }
        })

        clearTimeout(timeoutId)

        if (!response.ok) {
            console.error(`Embedly API error: ${response.status}`)
            return { error: `API error: ${response.status}` }
        }

        const data = await response.json()

        if (!data || data.error) {
            console.error('Embedly returned an error:', data.error_message || data.error || 'Unknown error')
            return { error: data.error_message || data.error || 'Unknown error' }
        }

        const filteredImages = (data.images || [])
            .filter(img => img && img.url && img.width > 100 && img.height > 100)
            .map(img => ({
                url: img.url,
                width: img.width,
                height: img.height
            }))

        return {
            title: data.title || '',
            description: data.description || '',
            url: data.url || url,
            images: filteredImages,
            provider: {
                faviconUrl: data.favicon_url || null,
                name: data.provider_name || data.site_name || new URL(url).hostname
            }
        }
    } catch (error) {
        clearTimeout(timeoutId)

        if (error.name === 'AbortError') {
            console.error('Embedly request timed out')
            return { error: 'Request timed out' }
        }

        console.error('Error fetching Embedly preview:', error)
        return { error: error.message || 'Failed to fetch preview from Embedly' }
    }
} 