import { useEffect, useState } from 'react'
import API from 'app/api'
import { SolitoImage } from 'solito/image'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button, Avatar, Spinner, TextField, XStack, YStack, Text } from '@bp/ui'
import { useUserInfoStore } from 'app/store'
import { SendIcon, InsertPhotoIcon, CancelIcon } from '@bp/ui/src/Iconsx'
import * as ImagePicker from 'expo-image-picker'
import Post from './post'
import { useStream } from 'app/lib/stream/useStream'
import URLPreviewCard, { convertGetStreamOgFetchToEmbedly } from './urlPreviewCard'
import { fetchEmbedlyPreview } from 'app/utils/embedly'
import Sentry from 'app/sentry'

function NewPost({ userId, feedSlug }) {
  const queryClient = useQueryClient()
  const avatar = useUserInfoStore((state) => state.avatar)
  const [postText, setPostText] = useState()
  const [postPreview, setPostPreview] = useState()
  const [errorMessage, setErrorMessage] = useState('')
  const client = useStream((s) => s.feeds)

  const handleChangeText = (value) => setPostText(value)

  const addPostMutation = useMutation({
    mutationFn: ({ postText, type, images, preview, links }) =>
      API.post.createPost(postText, type, images, preview, links),
    onMutate: () => {
      setErrorMessage('')
    },
    onSuccess: async (response) => {
      const newPost = {
        pending: true,
        activities: [
          {
            actor: { data: response.post.user },
            object: JSON.stringify(response.post),
          },
        ],
      }
      await queryClient.cancelQueries({ queryKey: ['gsFeedData', feedSlug, userId] })
      setPostPreview(newPost)
      setPostText('')
      setPicture(undefined)
      setLinks([])
      setPreviews({})
      setRemovedPreviewsUrls([])
    },
    onError: (error) => {
      Sentry.captureException(error, {
        tags: {
          component: 'NewPost',
          action: 'createPost'
        },
        extra: {
          userId,
          feedSlug,
        }
      })
      setErrorMessage(
        'Failed to create post. Please try again later.'
      )
      console.error('Error creating post:', error)
    },
    onSettled: () => {
      // We cannot re-fetch because the new post is not yet in the GS database.
      //queryClient.invalidateQueries({ queryKey: ['gsFeedData', feedSlug, userId] })
    },
  })

  const createNewPost = async () => {
    try {
      const previewsArray = Object.values(previews)
      const hasPreview = previewsArray.length > 0
      const preview = hasPreview ? convertGetStreamOgFetchToEmbedly(previewsArray[0]) : undefined
      const links = Object.keys(previews)
      const type = picture ? 'photo' : hasPreview ? 'link' : 'text'
      const images = type === 'photo' ? [`data:image/png;base64,${picture.base64}`] : undefined
      addPostMutation.mutate({ postText, type, images, preview, links })
    } catch (e) {
      console.error(e)
    }
  }

  const [picture, setPicture] = useState(undefined)
  const onUploadImage = async () => {
    try {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
      if (status !== 'granted') {
        return
      }

      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 1,
        base64: true,
      })
      if (!result.canceled) {
        setPicture(result.assets[0])
      }
    } catch (e) { }
  }

  const [links, setLinks] = useState([])
  const [previews, setPreviews] = useState({})
  const [loading, setLoading] = useState({})
  const [removedPreviewsUrls, setRemovedPreviewsUrls] = useState([])

  // Extract URLs from the text using the regex, debounced.
  useEffect(() => {
    const handler = setTimeout(() => {
      if (!postText || Object.keys(previews).length > 0) return
      const urlRegex = /(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}(?:\/[^\s]*)?/gi

      const matchedLinks = (postText.match(urlRegex) || []).filter(
        (url) => !removedPreviewsUrls.includes(url)
      )
      // Right now the API doesn't support multiple previews so we limit previews to 1.
      setLinks(matchedLinks.length > 0 ? [matchedLinks[0]] : [])
    }, 500)

    return () => clearTimeout(handler)
  }, [postText, previews])

  // Fetch previews for each URL
  // Right now the API doesn't support multiple previews but the loop is fine.
  useEffect(() => {
    if (links.filter((url) => !removedPreviewsUrls.includes(url)).length > 0) {
      const fetchPreviews = async () => {
        const previewData = {}
        const loadingState = {}

        // Set initial loading state to true for each URL
        links.forEach((url) => {
          if (!removedPreviewsUrls.includes(url)) {
            loadingState[url] = true
          }
        })
        setLoading(loadingState)

        for (const url of links) {
          if (removedPreviewsUrls.includes(url)) continue
          try {
            const gsPreview = await client.og(url)

            const preview = convertGetStreamOgFetchToEmbedly(gsPreview)

            const hasSuitableImages = preview.images?.some(img => img?.width > 200 || img?.height > 200)

            if (!hasSuitableImages) {
              const embedlyPreview = await fetchEmbedlyPreview(url)

              if (!embedlyPreview.error) {
                previewData[url] = {
                  ...preview,
                  ...embedlyPreview,
                  title: embedlyPreview.title || preview.title,
                  description: embedlyPreview.description || preview.description,
                  images: embedlyPreview.images?.length ? embedlyPreview.images : preview.images
                }
              } else {
                previewData[url] = preview
              }
            } else {
              previewData[url] = preview
            }
          } catch (error) {
            try {
              const embedlyPreview = await fetchEmbedlyPreview(url)
              previewData[url] = embedlyPreview
            } catch (embedlyError) {
              previewData[url] = { error: 'Failed to fetch preview' }
            }
          } finally {
            loadingState[url] = false
            setLoading({ ...loadingState })
          }
        }
        setPreviews(previewData)
      }

      fetchPreviews()
    }
  }, [links, removedPreviewsUrls])

  const removePreview = (url) => () => {
    setRemovedPreviewsUrls((list) => [...list, url])
    setPreviews(({ [url]: _, ...rest }) => rest)
  }

  return (
    <YStack>
      <YStack
        backgroundColor="$light"
        paddingHorizontal="$4"
        paddingVertical="$4"
        borderRadius="$4"
        marginBottom="$4"
        gap="$4"
      >
        <XStack gap="$3" alignItems="flex-start">
          <Avatar circular size="$5" src={avatar?.md} />
          <YStack flex={1}>
            <TextField
              flex={1}
              value={postText}
              placeholder="What's up?"
              onChangeText={handleChangeText}
              inputProps={{ paddingRight: 50, multiline: true }}
            />
            <Button
              onPress={onUploadImage}
              style={{ position: 'absolute', right: 0, top: 8, zIndex: 999 }}
              icon={<InsertPhotoIcon size="$1.2" color="$secondaryDarker" />}
            />
          </YStack>
        </XStack>
        {picture && (
          <XStack justifyContent="center">
            <SolitoImage
              alt="Post image"
              key="imageUpload"
              width={320}
              height={300}
              src={picture.uri}
            />
          </XStack>
        )}
        <XStack justifyContent="flex-end">
          <Button
            variant="primary"
            disabled={addPostMutation.isPending}
            onPress={createNewPost}
            iconAfter={addPostMutation.isPending ? Spinner : SendIcon}
          >
            Post
          </Button>
        </XStack>
        {errorMessage ? (
          <XStack paddingVertical="$2">
            <Text color="$errorDarker" textAlign="right" width="100%">
              {errorMessage}
            </Text>
          </XStack>
        ) : null}
        <YStack gap="$3">
          {links.map((url) => {
            if (removedPreviewsUrls.includes(url)) return null
            return (
              <YStack key={url} gap="$3">
                {loading[url] ? (
                  <YStack
                    elevation={5}
                    backgroundColor="$lighter"
                    gap="$3"
                    padding="$3"
                    borderRadius="$4"
                  >
                    <Spinner />
                    <Text centered>Loading preview...</Text>
                  </YStack>
                ) : (
                  <YStack gap="$2">
                    <YStack style={{ position: 'absolute', right: 28, top: 20, zIndex: 999 }}>
                      <Button
                        variant="tertiary"
                        onPress={removePreview(url)}
                        icon={<CancelIcon size="$2"
                          color="$darkest" backgroundColor="$light"
                          borderRadius="100%" />}
                      />
                    </YStack>
                    <URLPreviewCard {...convertGetStreamOgFetchToEmbedly(previews[url])} />
                  </YStack>
                )
                }
              </YStack>
            )
          })}
        </YStack>
      </YStack>
      {postPreview && <Post post={{ item: postPreview }} />}
    </YStack >
  )
}

export default NewPost
